<template>
  <v-flex class="participant-waiting">
    <video ref="videoElement" autoplay playsinline />
    <v-layout align-center class="overlay" column justify-center>
      <div v-if="!isCancelled" class="title">
        {{ $t("visits.waiting.title") }}
      </div>
      <div v-if="!isCancelled" class="sub-title">
        {{ $t("visits.waiting.subtitle") }}
      </div>
      <div class="timer">
        {{ timeUntilStart }}
      </div>
      <div class="buttons">
        <v-btn
          v-if="patientCanSendNotification"
          :loading="notificationByPatientLoading"
          class="buttons-start"
          color="success"
          dark
          depressed
          large
          @click="onNotifyPractitioner"
        >
          I'm ready
        </v-btn>
        <v-btn v-if="isPractitioner" class="buttons-start" color="success" dark depressed large @click="startRoom">
          {{ $t("visits.waiting.startVisit") }}
        </v-btn>
        <v-btn v-if="isPractitioner" class="buttons-cancel" color="white" large outlined @click="cancelDialog = true">
          {{ $t("visits.waiting.cancelVisit") }}
        </v-btn>
        <v-btn
          v-if="!isPractitioner && roomIsStarted"
          class="buttons-start"
          color="success"
          dark
          depressed
          large
          @click="userReady"
        >
          {{ $t("visits.waiting.joinVisit") }}
        </v-btn>
      </div>
    </v-layout>
    <div v-if="isPatientWaiting" class="message">
      <div class="message-dot" />
      <span>{{ $t("visits.waiting.waitingForYou", { name: patientName }) }}</span>
    </div>
    <CancelVisit v-model="cancelDialog" @success="onCancel" />
  </v-flex>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import { VideoRoomsApi } from "@/api/videoRooms";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";
import { sleep } from "@/utils/sleep";

import CancelVisit from "../CancelVisit";

export default Vue.extend({
  name: "WaitingRoom",
  data() {
    return {
      interval: null,
      timerInterval: null,
      timer: "",
      timerInSeconds: 0,
      cancelDialog: false,
      stream: null,
      notificationByPatientSent: false,
      notificationByPatientLoading: false,
    };
  },
  components: {
    CancelVisit,
  },
  computed: {
    ...mapState(useVideoRoomStore, ["isVideo", "room", "roomIsStarted", "encounter", "roomNotFound", "roomIsExpired"]),
    ...mapState(useAuthStore, ["role"]),
    isPractitioner() {
      return this.role === RolesEnum.Practitioner;
    },
    isPatient() {
      return [RolesEnum.EncounterParticipant, RolesEnum.Patient].includes(this.role);
    },
    patientCanSendNotification() {
      return !this.roomIsStarted && this.isPatient && !this.notificationByPatientSent && this.room?.id;
    },
    isPatientWaiting() {
      const fiveSecondsAgo = new Date(Date.now() - 5000);
      return (
        this.isPractitioner &&
        this.room &&
        this.room.patient &&
        new Date(this.room.patient.activeDate) >= fiveSecondsAgo
      );
    },
    isCancelled() {
      return this.encounter.status === "cancelled";
    },
    timeUntilStart() {
      if (this.isPatient) {
        if (this.roomIsStarted) {
          return this.$t("visits.waiting.visitBegun");
        }
        if (this.roomNotFound || this.roomIsExpired) {
          return this.$t("visits.waiting.visitExpired");
        }
        if (this.encounter.status === "cancelled") {
          return this.$t("visits.waiting.visitCancelled");
        }
        if (this.encounter.status === "isExpired") {
          return this.$t("visits.waiting.visitCancelled");
        }
        if (this.roomIsStarted) {
          return this.$t("visits.waiting.visitBegun");
        } else {
          if (this.timerInSeconds === 0) {
            return this.$t("visits.waiting.waitPractitioner");
          }
          return this.timer;
        }
      } else if (this.isPractitioner) {
        return this.timer;
      }
      return "";
    },
    patientName() {
      if (this.encounter && this.encounter.patient && this.encounter.patient.name) {
        return this.encounter.patient.name;
      }
      return this.$t("visits.waiting.patient");
    },
  },
  methods: {
    ...mapActions(useVideoRoomStore, ["startRoom", "getRoomStatus"]),
    ...mapActions(useVideoRoomStore, ["setUserIsReady"]),
    async onNotifyPractitioner() {
      this.notificationByPatientLoading = true;
      try {
        await VideoRoomsApi.access(this.room.id, { notifyOnly: true });
        snackBarEventBus.$emit(snackBarEventName, {
          message: "We have sent a notification to the practitioner",
          type: "success",
        });
        this.notificationByPatientSent = true;
      } catch (e) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Can't complete the request",
          type: "error",
        });
      }
      this.notificationByPatientLoading = false;
    },

    async turnOnVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement && navigator.mediaDevices.getUserMedia) {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoElement.srcObject = this.stream;
      }
    },
    async turnOffVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement) {
        videoElement.srcObject = null;
      }
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          this.stream.removeTrack(track);
          track.stop();
        });
        this.stream = null;
      }
    },
    async userReady() {
      await this.turnOffVideo();
      setTimeout(() => {
        this.setUserIsReady(true);
      }, 200);
    },
    timerUpdate() {
      let diff = DateTime.fromISO(this.room.expectedStartDate).diffNow("seconds").as("seconds");
      if (diff < 0) {
        diff = 0;
      }
      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);
      const seconds = Math.floor((diff % 3600) % 60);
      const hoursStr = hours ? `${hours} hours, ` : "";
      const minutesStr = minutes ? `${minutes} minutes, ` : "";
      const secondsStr = `${seconds} seconds`;
      this.timer = `${hoursStr}${minutesStr}${secondsStr}`;
      this.timerInSeconds = diff;
      if (!this.timerInSeconds) {
        window.clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    async onCancel() {
      await sleep(5000);
      await this.$router.push({ name: routesEnum.practitionerHome });
    },
  },
  async mounted() {
    if (this.isVideo) {
      await this.turnOnVideo();
    }
    this.interval = setInterval(() => {
      this.getRoomStatus();
    }, 3000);
    this.timerInterval = setInterval(this.timerUpdate, 1000);
  },
  async beforeDestroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
    if (this.timerInterval) {
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
    await this.turnOffVideo();
  },
  watch: {
    async isVideo() {
      if (this.isVideo) {
        await this.turnOnVideo();
      } else {
        await this.turnOffVideo();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.message {
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  right: 20px;
  top: 21px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: $white;
  opacity: 0.86;

  &-dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    left: 794px;
    top: 135px;
    background: $success;
    border-radius: 6px;
    margin-right: 5px;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;
  color: white;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    opacity: 0.68;
  }

  .sub-title {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    mix-blend-mode: normal;
    opacity: 0.4;
  }

  .timer {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    @include mobile {
      font-size: 15px;
      line-height: 24px;
    }
    @media (max-width: 767.98px) {
      padding-bottom: 10px;
    }
  }

  .buttons {
    margin-top: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    &-start {
      margin-right: 15px;
    }

    &-cancel {
      opacity: 0.4;
    }
  }
}
</style>
